// src/Footer.js
import React from 'react';
import './Footer.css'; // Ensure this is the correct path to your Footer.css file

function Footer() {
  return (
      <footer className="footer-background py-4 text-center text-white">
        © 2024 City of Thieves. All rights reserved.
      </footer>
    );
}
  
export default Footer;
