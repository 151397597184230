// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import Home from './Home'; // Make sure to import the Home component
import Forum from './Forum';
import Chat from './Chat';
import './App.css';
import Terminal from './Terminal';
import MouseTrail from './MouseTrail';

function App() {
  return (
    <div className="App">
    <MouseTrail /> {/* This activates the mouse trail effect */}
    <><Router>
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <div className="sticky-header">
        <Header />
      </div>
      <main className="mt-8 mb-8">
        <Routes>
          <Route path="/" element={<Home />} /> {/* Home route */}
          <Route path="/forum" element={<Forum />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/terminal" element={<Terminal />} />
        </Routes>
      </main>
      <Footer />
    </Router>
    <div className="scroll-indicator">
        <i className="fas fa-chevron-down"></i> {/* FontAwesome icon */}
      </div></>
      </div>
  );
}

export default App;
