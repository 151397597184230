export class ShadowMarketModule {
  constructor(onExitCallback) {
    this.isActive = false;
    this.exitCallback = onExitCallback;
    // Sample inventory for the ShadowMarket
    this.inventory = [
      { id: "item-001", name: "Cybernetic Eye", description: "Enhances your vision with thermal and night vision capabilities.", price: "120 CryptoCoins", available: true },
      { id: "item-002", name: "Quantum Keylogger", description: "A stealthy keylogger capable of breaching quantum encryption.", price: "300 CryptoCoins", available: true },
      { id: "item-003", name: "Digital Cloak", description: "Makes your digital footprint undetectable.", price: "200 CryptoCoins", available: false } // This item is not available for purchase
    ];
  }

  handleCommand(cmd, args) {
    switch (cmd) {
      case 'sm_list':
        return this.listItems();
      case 'sm_detail':
        return this.detailItem(args[1]);
      case 'sm_buy':
        return this.purchaseItem(args[1]);
      case 'sm_exit':
        this.isActive = false;
        if (this.exitCallback) this.exitCallback();
        return "Exiting ShadowMarket.";
      default:
        return "Unknown ShadowMarket command.";
    }
  }

  listItems() {
    const itemList = this.inventory.map(item => `${item.name} - Price: ${item.price} - ${item.available ? 'Available' : 'Not Available'}`).join('\n');
    return `ShadowMarket Inventory:\n${itemList}`;
  }

  detailItem(itemId) {
    const item = this.inventory.find(i => i.id === itemId);
    if (!item) return "Item not found.";
    return `Item: ${item.name}\nDescription: ${item.description}\nPrice: ${item.price}\nStatus: ${item.available ? 'Available' : 'Not Available'}`;
  }

  purchaseItem(itemId) {
    const item = this.inventory.find(i => i.id === itemId);
    if (!item) return "Item not found.";
    if (!item.available) return `${item.name} is not available for purchase.`;
    // Assuming the purchase is always successful for demonstration purposes
    return `You have successfully purchased ${item.name} for ${item.price}.`;
  }
}
