// src/Forum.js
import React, { useEffect } from 'react';

const Forum = () => {
  useEffect(() => {
    // Redirects the user to the forum
    window.location.href = 'https://cityofthieves.io/forums';
  }, []);

  return null; // Since this component only redirects, it doesn't need to render anything
};

export default Forum;
