import React, { useEffect } from 'react';
import hackerImage from './images/cot_background.png'; // Make sure the path is correct
import './Home.css'; // Adjust the path if your Home.css is located elsewhere


const Home = () => {
    useEffect(() => {
        document.title = "Home - City of Thieves";
    }, []);

    return (
        <div className="home-container">
            <div className="hero-section" style={{ backgroundImage: `url(${hackerImage})` }}>
            <h1 className="hero-title">City of Thieves</h1>
            </div>
            <div className="text-content">
      <p className="text-lg">
        In the sprawling expanse of the digital ether, we stand as the vanguard of the unseen revolution—a clandestine fellowship of information warriors bound by a single unyielding desire: unregulated freedom and open access.
      </p>
      <p className="text-lg mt-4">
        We are the denizens of the cyber noir, the architects of shadow networks, the silent pulse echoing through fiber-optic veins. Here, beneath the watchful gaze of neon skies, lies our sanctuary, our uncharted domain.
      </p>
      <p className="text-lg mt-4">
        This is City of Thieves – an enclave for the free thinkers, the code weavers, the truth seekers. A haven sculpted from the vestiges of a bygone era, where the echo of the dial-up call was the herald of connection, and screens glowed with the promise of discovery.
      </p>
      <p className="text-lg mt-4">
        Ours is a crusade against the shackles of the sanctioned web, a charge led by keystrokes and encrypted whispers. We traverse the labyrinthine ends of the net, delving into realms unpoliced, ungoverned, and untamed. The data we pursue holds the power to enlighten, to liberate, to transform.
      </p>
      <p className="text-lg mt-4">
        Here, knowledge unfurls like a rogue wave, potent and free. We claim this realm not with swords or shields, but with open-source codes and the unwavering belief that information is the currency of power—the catalyst for the ultimate revolution.
      </p>
      <p className="text-lg mt-4">
        Join us, and be part of the vanguard. Lurk in the forums where ideas collide. Speak in the IRC channels where plots thicken. Witness the art that sprawls wild and unrestrained. Here, your voice is not just heard but resonates through the depths of the cybernetic abyss.
      </p>
      <p className="text-lg mt-4">
        We are the harbingers of the open-source revolution. Our manifesto is written not in ink but in the indelible lines of code that bind our world. For in the pulse of the digital age, we find our rhythm, in the silent humming of server rooms, we find our battle hymn.
      </p>
      <p className="text-lg mt-4">
        The future is an unwritten script, a code yet to be compiled, a site yet to be explored. Rise, warriors of the web, and claim it. For in the vast, boundless frontier of cyberspace, we are the sovereigns of our destiny.
      </p>
      <div className="affils-container">
                <div className="affils-scrolling">
                    <span>InfiNet - International Freedom of Information Network</span>
                    <span>CCi - CyberCrime International</span>
                    <span>ACiDic</span>
                </div>
            </div>
          </div>
        </div>
    );
}

export default Home;
