// src/Chat.js
import React, { useEffect } from 'react';

function Chat() {
    useEffect(() => {
        document.title = "Chat - City of Thieves";
      }, []);
    return <div>Welcome to the Chat</div>;
  }
  
  export default Chat;
  