export class RetroVaultModule {
  constructor(onExitCallback) {
    this.isActive = false;
    this.exitCallback = onExitCallback;
    this.collections = [
      { id: "game-001", name: "Retro Quest", description: "A classic adventure game.", encrypted: true },
      { id: "util-001", name: "CyberTool", description: "Utility software for coding.", encrypted: false },
      { id: "warez-001", name: "HackEmulator", description: "Emulator for vintage systems.", encrypted: true }
    ];
  }


  handleCommand(cmd, args) {
    // Adjust here if cmd is not part of args, otherwise use args[1] for the first argument.
    switch (cmd) {
      case 'rv_list':
        return this.listCollections();
      case 'rv_detail':
        return this.detailItem(args[1]); // Corrected to args[1] assuming args[0] is the command
      case 'rv_decrypt':
        return this.decryptArchive(args[1]); // Corrected to args[1] for the same reason
      case 'rv_exit':
        this.isActive = false;
        if (this.exitCallback) {
          this.exitCallback();
        }
        return "Exiting RetroVault.";

      default:
        return "Unknown RetroVault command.";
    }
  }


  listCollections() {
    const itemList = this.collections.map(item => `${item.name} - ${item.encrypted ? 'Encrypted' : 'Available'}`).join('\n');
    return `RetroVault Collections:\n${itemList}`;
  }

  detailItem(itemId) {
    const item = this.collections.find(i => i.id === itemId);
    if (!item) {
      return "Item not found.";
    }
    return `Item: ${item.name}\nDescription: ${item.description}\nStatus: ${item.encrypted ? 'Encrypted' : 'Available'}`;
  }

  decryptArchive(itemId) {
    const item = this.collections.find(i => i.id === itemId);
    if (!item) {
      return "Archive not found.";
    }
    if (!item.encrypted) {
      return `${item.name} is already decrypted.`;
    }
    item.encrypted = false;
    return `${item.name} decrypted successfully.`;
  }
}
