import React, { useEffect, useRef } from 'react';
import './MatrixRain.css'; // Assume you have some basic CSS for the canvas

const MatrixRain = ({ isActive, onClose }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!isActive) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const letters = Array(256).fill(1).map((_, i) => String.fromCharCode(i));
    const fontSize = 10;
    const columns = canvas.width / fontSize;
    const drops = Array(Math.floor(columns)).fill(0);

    function draw() {
      if (!isActive) return;

      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = '#0F0';
      ctx.font = `${fontSize}px monospace`;

      for (let i = 0; i < drops.length; i++) {
        const text = letters[Math.floor(Math.random() * letters.length)];
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

        if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }
        drops[i]++;
      }
      requestAnimationFrame(draw);
    }

    draw();

    return () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas on cleanup
    };
  }, [isActive]);

  return (
    <>
      {isActive && (
        <canvas ref={canvasRef} onClick={onClose} className="matrix-rain-canvas"></canvas>
      )}
    </>
  );
};

export default MatrixRain;
