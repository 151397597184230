// src/components/MouseTrail.js
import React, { useEffect } from 'react';
import './MouseTrail.css'; // Import CSS for styling

const MouseTrail = () => {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const binary = document.createElement('span');
      binary.classList.add('pointer-effect');
      binary.textContent = Math.random() > 0.5 ? '1' : '0';
      document.body.appendChild(binary);

      binary.style.left = `${e.pageX}px`;
      binary.style.top = `${e.pageY}px`;

      setTimeout(() => {
        document.body.removeChild(binary);
      }, 800); // Time until the element is removed
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return null; // This component does not render anything itself
};

export default MouseTrail;
