// src/Header.js
import { Link } from 'react-router-dom';
import React from 'react';
import headerImage from './images/header2.png'; // Make sure the path is correct
import cotLogo from './images/logo2.png'; // Path to your "CoT" logo


function Header() {
  return (
    <header style={{ backgroundImage: `url(${headerImage})` }} className="header-background">
      <div className="container mx-auto flex justify-between items-center p-4">
        <img src={cotLogo} alt="CoT Logo" className="cot-logo" /> {/* Logo added here */}
        <nav className="nav">
          <Link to="/" className="nav-button">Home</Link>
          <Link to="/forum" className="nav-button">Forum</Link>
          <Link to="/chat" className="nav-button">IRC Chat</Link>
          <Link to="/terminal" className="nav-button">!H4CK M3!</Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;
