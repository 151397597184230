export class ChallengeModule {
    constructor() {
        this.isChallengeActive = false;
        this.challengeData = {
            passphrase: "CyberPunk2024",
            encryptedMessage: "This is a secret message.",
            isSolved: false
        };
    }

    startChallenge() {
        this.isChallengeActive = true;
        return "A new challenge has started. Decrypt the message.";
    }

    attemptDecryption(passphrase) {
        if (!this.isChallengeActive) {
            return "No active challenge.";
        }
        if (passphrase === this.challengeData.passphrase) {
            this.isChallengeActive = false;
            this.challengeData.isSolved = true;
            return `Decryption successful: ${this.challengeData.encryptedMessage}`;
        } else {
            return "Decryption failed. Try again.";
        }
    }

    getChallengeStatus() {
        if (!this.isChallengeActive) {
            return "No active challenge.";
        }
        return "Challenge is active. Decrypt the message.";
    }

    concludeChallenge() {
        // Reset the challenge state here
        this.isChallengeActive = false;
        this.challengeData.isSolved = false; // Reset this if you want the challenge to be solvable again
        return "Exiting challenge mode. What would you like to do next?";
    }
}
