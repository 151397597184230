export class DigitalDawnModule {
    constructor(onExitCallback) {
      this.isActive = false;
      this.exitCallback = onExitCallback;
      // Sample data for DigitalDawn, focusing on early digital culture and artifacts
      this.artifacts = [
        { id: "artifact-001", name: "First Web Page", description: "A replica of the first web page ever created, marking the birth of the World Wide Web.", encrypted: true, content: "This page, created by Tim Berners-Lee, heralded a new era of information sharing." },
        { id: "artifact-002", name: "Vintage Software", description: "Collection of software that paved the way for modern computing, including early operating systems and applications.", encrypted: false, content: "These software programs were the giants on whose shoulders today's technology stands." },
        { id: "artifact-003", name: "Digital Manuscripts", description: "Early digital writings on programming, ethics, and culture from the pioneers of computing.", encrypted: true, content: "Insights into the minds that envisioned the future of technology, shaping the digital world." }
      ];
    }
  
    handleCommand(cmd, args) {
      switch (cmd) {
        case 'list':
          return this.listArtifacts();
        case 'decrypt':
          return this.decryptArtifact(args[0]); // Assuming args[0] is the artifact name/id
        case 'explore':
          return this.exploreArtifact(args[0]); // Assuming args[0] is the artifact name/id
        case 'dd_exit':
          this.isActive = false;
          if (this.exitCallback) this.exitCallback();
          return "Exiting DigitalDawn.";
        default:
          return "Unknown DigitalDawn command. Available commands are 'list', 'decrypt <artifact>', 'explore <artifact>', and 'dd_exit'.";
      }
    }
  
    listArtifacts() {
      const artifactsList = this.artifacts.map(artifact => `${artifact.name} - ${artifact.encrypted ? 'Encrypted' : 'Available'}`).join('\n');
      return `DigitalDawn Artifacts:\n${artifactsList}\nUse 'explore <artifact>' to learn more about an artifact or 'decrypt <artifact>' to access its contents.`;
    }
  
    decryptArtifact(artifactName) {
      const artifact = this.artifacts.find(artifact => artifact.name.toLowerCase() === artifactName.toLowerCase());
      if (!artifact) return "Artifact not found.";
      if (!artifact.encrypted) return `${artifactName} is already available for exploration.`;
  
      artifact.encrypted = false; // Assuming decryption is successful
      return `${artifactName} decrypted successfully. Use 'explore ${artifactName}' to uncover its secrets.`;
    }
  
    exploreArtifact(artifactName) {
      const artifact = this.artifacts.find(artifact => artifact.name.toLowerCase() === artifactName.toLowerCase());
      if (!artifact) return "Artifact not found.";
      if (artifact.encrypted) return `${artifactName} is encrypted. Use 'decrypt ${artifactName}' to access its contents.`;
  
      return `Exploring ${artifactName}:\nDescription: ${artifact.description}\nContent: ${artifact.content}`;
    }
  }
  