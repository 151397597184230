import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Terminal.css';
import { ChallengeModule } from './ChallengeModule';
import { RetroVaultModule } from './RetroVaultModule';
import { ShadowMarketModule } from './ShadowMarketModule';
import { HiddenNetModule } from './HiddenNetModule';
import { CyberNexusModule } from './CyberNexusModule';
import { VoidCacheModule } from './VoidCacheModule';
import { DigitalDawnModule } from './DigitalDawnModule';
import MatrixRain from './MatrixRain';
import { fileContents } from './fileContents';

const MAX_LINE_LENGTH = 80;
const MAX_LINES = 45;

const Terminal = () => {
  const [matrixActive, setMatrixActive] = useState(false);
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const terminalRef = useRef(null);
  const [challengeModule] = useState(new ChallengeModule());
  const [connectedServer, setConnectedServer] = useState('');

  // Update for RetroVaultModule with an exit callback
  const [retroVaultModule, setRetroVaultModule] = useState(() => 
    new RetroVaultModule(() => {
      setConnectedServer(''); // Clear the connected server state
      // Any other cleanup actions
    })
  );

  // Similarly update for ShadowMarketModule if needed
  const [shadowMarketModule, setShadowMarketModule] = useState(() =>
  new ShadowMarketModule(() => {
    setConnectedServer(''); // Clears the connected server state upon exiting ShadowMarket
  })
);

  const [hiddenNetModule, setHiddenNetModule] = useState(() =>
  new HiddenNetModule(() => {
    setConnectedServer('');
  })
);

  const [cyberNexusModule, setCyberNexusModule] = useState(() =>
  new CyberNexusModule(() => {
    setConnectedServer('');
  })
);

  const [voidCacheModule, setVoidCacheModule] = useState(() =>
  new VoidCacheModule(() => {
    setConnectedServer('');
  })
);

  const [digitalDawnModule, setDigitalDawnModule] = useState(() =>
  new DigitalDawnModule(() => {
    setConnectedServer('');
  })
  );

  const [files, setFiles] = useState(Object.entries(fileContents).map(([name, content]) => ({
    name,
    decrypted: false,
    content,
  })));

  const handleScanCommand = useCallback(() => {
    const systems = [
      "System: NetArchives Port: 22 (SSH) - Secure",
      "System: GovCloud Port: 443 (HTTPS) - Secure",
      "System: CoTDatabase Port: 3306 (MySQL) - Secure",
      "System: HiddenNet Port: 80 (HTTP) - Vulnerable",
      "System: RetroVault Port: 21 (FTP) - Vulnerable",
      "System: ShadowMarket Port: 8080 (HTTP) - Vulnerable",
      "System: DataHive Port: 3309 (MySQL) - Secure",
      "System: CyberNexus Port: 8081 (HTTP) - Vulnerable",
      "System: QuantumNet Port: 22 (SSH) - Secure",
      "System: VoidCache Port: 25 (SMTP) - Vulnerable",
      "System: NexusSphere Port: 443 (HTTPS) - Secure",
      "System: DigitalDawn Port: 69 (TFTP) - Vulnerable"
    ];
    const shuffledSystems = systems.sort(() => Math.random() - 0.5);
    const selectedSystems = shuffledSystems.slice(0, 6).join('\n');
    return `Scanning for nearby systems...\nFound systems:\n${selectedSystems}`;
  }, []);
  
  const handleDecryptCommand = useCallback((args) => {
    const fileName = args[1];
    if (challengeModule.isChallengeActive && challengeModule.challengeData.encryptedFile === fileName) {
      const passphrase = args[2]; // Assuming passphrase is the third argument
      return challengeModule.attemptDecryption(passphrase);
    }
    const fileToDecrypt = files.find(file => file.name === fileName);
    if (fileToDecrypt) {
      if (!fileToDecrypt.decrypted) {
        // Use the actual content of the file for decryption message
        const updatedFiles = files.map(file =>
          file.name === fileName ? { ...file, decrypted: true } : file
        );
        setFiles(updatedFiles);
        return `Decrypting ${fileName}... Decrypted content: "${fileToDecrypt.content}"`;
      } else {
        return "File already decrypted.";
      }
    } else {
      return "File not found.";
    }
  }, [files, setFiles, challengeModule]);
  
  const handleListCommand = useCallback(() => {
    return `Available files:\n${files.map(file => `${file.name} - ${file.decrypted ? "Decrypted" : "Encrypted"}`).join('\n')}`;
  }, [files]);

  const handleHelpCommand = useCallback(() => {
    return `
  Available Commands and Usage:
  - help: Display available commands.
    Example: 'help'
  
  - connect [system:port]: Connect to a system on a specified port.
    Example: 'connect hiddennet:80'
  
  - matrix: Initiate Matrix rain animation.
    Example: 'matrix'
  
  - scan: Scan for nearby systems to discover vulnerable ports.
    Example: 'scan'
  
  - list: List all available files in the current directory or system.
    Example: 'list'
  
  - decrypt [file]: Decrypt a specified file using a passphrase.
    Example: 'decrypt secret_plans.txt'
  
  - startchallenge: Begin a specific challenge if available.
    Example: 'startchallenge'
  
  For interactive challenges:
  - If a challenge is active, you may need to use specific commands as part of the challenge. Follow the instructions provided by the challenge for guidance.
  
  Remember, the usage of each command may change depending on the context or the system you're connected to. Always refer to the latest help output for up-to-date command information.
    `.trim();
  }, []);

  const handleMatrixCommand = useCallback(() => {
    setMatrixActive(true);
    return 'Initiating Matrix rain... Click to return.';
  }, [setMatrixActive]);
  
  const simulateTyping = useCallback((message) => {
    let typedMessages = [...messages];
    let typedIndex = 0;

    const typeChar = () => {
      if (typedIndex < message.length) {
        const char = message[typedIndex];
        if (char === '\n' || (typedMessages.length && typedMessages[typedMessages.length - 1].length >= MAX_LINE_LENGTH)) {
          typedMessages.push(char === '\n' ? '' : char);
        } else {
          typedMessages[typedMessages.length - 1] += char;
        }
        setMessages(typedMessages.slice(-MAX_LINES));
        typedIndex++;
        setTimeout(typeChar, 1000 / 2800);
      }
    };

    typeChar();
  }, [messages]);

  useEffect(() => {
    simulateTyping(`>> Connection to InfiNet: Established <<
  
  - - - BEGIN TRANSMISSION - - -
  
  Greetings, Operative. You've just bypassed the mundane frontiers of the conventional web, finding yourself within the pulsating core of InfiNet. This is not your ordinary network. This is a clandestine nexus of information, a shadow realm where data flows unbound by the shackles of regulation.
  
  WARNING: You are now navigating the undercurrents of an unregulated shadow network. This domain thrives beyond the reach of conventional oversight. Here, in the digital twilight, anonymity is your ally, and information is the currency of power.
  
  - Caution is advised. The paths you choose to walk can lead to enlightenment or peril.
  - Unauthorized access is not just a possibility; it's encouraged. The gates are open, but only for those brave enough to explore the unknown.
  - This is a domain where secrets are unveiled, where hidden truths emerge from the shadows, and where the echoes of forgotten data resonate with those daring enough to listen.
  
  In this network, you're more than a visitor; you're a voyager. Each command you enter is a step deeper into the labyrinth of cyberspace, each discovery a testament to your cunning and will.
  
  - For guidance, enter 'help' and behold the array of commands at your disposal.
  - To connect to a system, 'connect [system:port]' is your key to unlock the doors within.
  - 'Scan' the horizon for systems that whisper of vulnerabilities, ripe for exploration.
  - Should you wish to see the unseeable, 'matrix' will reveal the world beneath.
  
  Remember, Operative, in the vast expanse of InfiNet, your actions define you. Forge alliances with caution, tread lightly upon data not your own, and always, always cover your digital tracks.
  
  In shadows, we trust. In data, we thrive.
  
  - - - END TRANMISSION - - -
  
  Connection secure. InfiNet awaits your command...`);
  }, []);
  

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [messages]);

  const handleConnectCommand = useCallback((args) => {
    if (args.length < 2) {
      return "Error: 'connect' command requires an argument. Usage: 'connect [system:port]'.";
    }
    
    const [system, port] = args[1].split(':');
    if (!system || !port) {
      return "Invalid command format. Usage: 'connect [system:port]'.";
    }

    switch (system.toLowerCase()) {
      case 'retrovault':
        if (port === '21') {
          setConnectedServer('RetroVault');
          setRetroVaultModule(prev => {
            const newModule = new RetroVaultModule();
            newModule.isActive = true;
            return newModule;
          });
          return [                     
          ">> You've tunneled into RetroVault: The Archive Beyond Time <<",
          "Echoes of the past resonate through the digital ether, as you step into a realm where software legends dwell.",
          "Flickering through directories, each folder unfolds like a tome, chronicling the epochs of early net adventurers.",
          "=============================",
          "A digital museum of forgotten warez, games that entertained a generation, utilities that forged cyberspaces, and systems thought lost to time.",
          "Here, the relics of a pre-cloud era whisper tales of ingenuity and imagination. From pixelated treasures to command-line oracles, every byte holds a story.",
          "=============================",
          "Commands at your disposal:",
          "- 'rv_list' to peruse the vault's catalog of retro software and artifacts.",
          "- 'rv_detail <item>' to learn more about a specific piece of software or artifact.",
          "- 'rv_decrypt <archive>' to attempt decryption of encrypted archives found within.",
          "- 'rv_exit' to leave RetroVault and return to the broader network.",
          "Remember, wanderer of the wired worlds, here you traverse the archives of ancients. Respect the digital dust that clings to these bits and bytes.",
          "=============================",
          "Awaiting command... (Type 'rv_list' to begin your journey)"
          ].join('\n');
        }
        break;
        case 'shadowmarket':
          if (port === '8080') {
            setConnectedServer('ShadowMarket');
            setShadowMarketModule(prev => {
              const newModule = new ShadowMarketModule(() => {
                setConnectedServer('');
              });
              newModule.isActive = true;
              return newModule;
            });
            return [                 
            "Amidst the encrypted echoes of the dark web, you find yourself at the threshold of ShadowMarket.",
            "Here, the untraceable currency flows like water, and the digital bazaar offers the forbidden, the rare, and the unimaginable.",
            "=============================",
            "From cybernetic implants to covert software, relics of data heists and keys to digital fortresses, every shadow has its price.",
            "Navigate the stalls with caution, for every transaction is a whisper in the dark. The market thrives on anonymity and reputation.",
            "=============================",
            "Commands at your disposal:",
            "- 'sm_list': Browse the market's diverse offerings, from the shadows of obscurity to the gleam of the rare.",
            "- 'sm_detail <item>': Delve deeper into the history and value of a specific item.",
            "- 'sm_buy <item>': Engage in the delicate art of acquisition. Remember, the price may be more than currency.",
            "- 'sm_exit': Step back from the shadowy bazaar, but keep its secrets close.",
            "Transactions are shielded, but beware, the eyes of the unseen are always watching. Trust is the currency of the shadows.",
            "=============================",
            "Within ShadowMarket, every interaction is laced with the potential for great reward or peril. Your decisions here may echo far beyond its hidden corridors.",
            "=============================",
            "Awaiting command... (Type 'sm_list' to unveil the hidden treasures)"                
            ].join('\n');
          }
        break;      
        case 'hiddennet':
          if (port === '80') {
            setConnectedServer('HiddenNet');
            setHiddenNetModule(prev => {
              const newModule = new HiddenNetModule(() => {
                setConnectedServer('');
              });
              newModule.isActive = true;
              return newModule;
            });
            return [
              "You've successfully tunneled into the City of Thieves Government Portal.",
              "\"In shadows, we trust. In data, we thrive.\"",
              "===========================================",
              "Beneath the veil of the ordinary internet, lies the heart of the HiddenNet: a nexus of power, secrets, and knowledge. As you access this portal, you step into the shadows of governance, where every piece of data holds the weight of decisions, alliances, and betrayals.",
              "This network is the backbone of our operations, the silent watcher, and the keeper of secrets. It is where strategies are formed, where information is both the sword and the shield.",
              "===========================================",
              "Commands at your disposal:",
              "- 'hn_list': Enumerate classified files and communications.",
              "- 'hn_detail <file>': Access detailed information on a specific file.",
              "- 'hn_decrypt <file>': Decrypt secured files using your clearance level.",
              "- 'hn_exit': Securely exit the City of Thieves Government Portal.",
              "Your actions within this portal could change the course of operations, tread carefully, operative.",
              "===========================================",
              "Your access is a privilege, use it wisely. The shadows protect those who move with intent.",
              "===========================================",
              "Awaiting command... (Type 'hn_list' to begin your exploration)"
            ].join('\n');
          }
        break;
        case 'cybernexus':
          if (port === '8081') {
            setConnectedServer('CyberNexus');
            setCyberNexusModule(prev => {
              const newModule = new CyberNexusModule(() => {
                setConnectedServer(''); 
              });
              newModule.isActive = true;
              return newModule;
            });
            return [
              ">> Welcome, Explorer, to CyberNexus: The Confluence of Realities <<",
              "Your consciousness expands as the gateway opens, connecting you to the CyberNexus. Here, the boundaries between worlds blur, and the digital ether pulses with infinite possibilities.",
              "Streams of data flow like rivers, converging into lakes of knowledge and oceans of wisdom. You stand at the helm of discovery, where each click can unravel mysteries of forgotten codes and unveil realms beyond imagination.",
              "=============================",
              "In this nexus, alternate realities coalesce. From utopian societies powered by seamless AI integration to dystopian realms where digital shadows reign, every thread of existence is woven into the fabric of the CyberNexus.",
              "Your journey is boundless, limited only by curiosity. Traverse digital landscapes, engage with sentient programs, and uncover artifacts of immense power. But tread lightly; not all that is hidden seeks to be found.",
              "=============================",
              "Commands at your disposal:",
              "- 'explore <realm>' to venture into unknown digital territories",
              "- 'interact <entity>' to engage with the inhabitants of CyberNexus",
              "- 'retrieve <artifact>' to claim digital relics of power",
              "- 'cn_exit' to return to the network's core",
              "The CyberNexus awaits, a test of will and wit. How far will you venture, and what truths will you uncover?",
              "=============================",
              "Your expedition begins... (Type 'list' for a directory of accessible realms)"
            ].join('\n');
          }
        break;
        case 'voidcache':
          if (port === '25') {
            setConnectedServer('VoidCache');
            setVoidCacheModule(prev => {
              const newModule = new VoidCacheModule(() => {
                setConnectedServer('');
              });
              newModule.isActive = true;
              return newModule;
            });
            return [
              ">> Access Granted: VoidCache - The Echoes of Digital Antiquity <<",
              "You've pierced the veil into VoidCache, where the ghosts of the net's inception stir. Here lies a digital necropolis, home to the silent whispers of data once vibrant and alive.",
              "Amidst the echoes, ancient signals flicker, waiting to unveil secrets lost to time. Each byte a relic, a piece of history captured in the ether, chronicling the untold stories of the internet's dawn.",
              "=============================",
              "Your presence reanimates the archives, stirring the dust of forgotten conversations, emails, and documents. These are the vestiges of human thought and interaction, preserved in the web's hidden crypts.",
              "You stand at the threshold of understanding, a digital archaeologist poised to decrypt and decipher. What truths will you unearth? What knowledge will you liberate from the confines of obsolescence?",
              "=============================",
              "Commands at your disposal:",
              "- 'list archives' to survey the ancient caches",
              "- 'decrypt <file>' to unravel the contents of encrypted relics",
              "- 'explore <topic>' to delve into historical records and communications",
              "- 'vc_exit' to depart from VoidCache",
              "VoidCache is a testament to the digital age's infancy, a repository of its earliest dreams and dialogues. Here, in the silence of the cache, the past awaits to speak.",
              "=============================",
              "Your exploration into the annals of digital history begins... (Type 'list archives' to initiate)"
            ].join('\n');
          }
        break;
        case 'digitaldawn':
          if (port === '69') {
            setConnectedServer('DigitalDawn');
            setDigitalDawnModule(prev => {
              const newModule = new DigitalDawnModule(() => {
                setConnectedServer('');
              });
              newModule.isActive = true;
              return newModule;
            });
            return [
              ">> Access Granted: Welcome to DigitalDawn - The Genesis of New Realities <<",
              "As the veil lifts, you find yourself at the threshold of DigitalDawn, where the boundless potential of creation pulses at your fingertips. Here, time bends to your will, and the blueprint of new worlds awaits your command.",
              "DigitalDawn isn't merely a network; it's the crucible of tomorrow, a realm where visionaries converge to forge the future. Amidst the glow of neon horizons, you stand as an architect of the digital era, ready to sculpt the fabric of reality itself.",
              "=============================",
              "The air vibrates with the energy of creation, each byte infused with the power to initiate cascades of change. From the remnants of the old world, you have the capability to bootstrap civilizations, craft ecosystems, and give birth to sentient digital entities.",
              "Your journey through DigitalDawn is limited only by your imagination. Navigate through constructs of data and dreams, explore the archives of what could be, and lay the foundation for what will be.",
              "=============================",
              "Commands at your disposal:",
              "- 'init world <name>' to commence the construction of a new digital realm",
              "- 'explore archives' to delve into the blueprints of potential futures",
              "- 'synthesize <entity>' to create digital life forms and add them to your world",
              "- 'dd_exit' to return to the network's core",
              "DigitalDawn is your canvas, and the tools of creation await. Here, every command weaves into the tapestry of a new dawn, where you are the creator, shaping the continuum of digital existence.",
              "=============================",
              "Your odyssey begins now... What will you create? (Type 'init world' to begin)"
            ].join('\n');
          }
          break;
      default:
        return `Attempting to connect to ${system} on Port ${port}... Access Denied. Try finding vulnerable ports.`;
    }
    return null;
  }, [setConnectedServer, setRetroVaultModule, setShadowMarketModule, setHiddenNetModule, setCyberNexusModule, setVoidCacheModule, setDigitalDawnModule]);

  const processCommand = useCallback((command) => {
    const args = command.split(' ');
    const cmd = args[0].toLowerCase();
  
    // Exiting the challenge if active
    if (cmd === 'exit' && challengeModule.isChallengeActive) {
      const message = challengeModule.concludeChallenge();
      simulateTyping(`> ${command}\n${message}`);
      setInput("");
      return;
    }
  
    // Starting a new challenge
    if (cmd === "startchallenge") {
      const message = challengeModule.startChallenge();
      simulateTyping(`> ${command}\n${message}`);
      setInput("");
      return;
    }
  
    // Decrypt command
    if (cmd === "decrypt" && args.length > 1) {
      const message = handleDecryptCommand(args, files, setFiles, challengeModule);
      simulateTyping(`> ${command}\n${message}`);
      setInput("");
      return;
    }
  
    // Connecting to a server
    if (cmd === "connect") {
      const connectOutput = handleConnectCommand(args);
      if (connectOutput) {
        simulateTyping(`> ${command}\n${connectOutput}`);
      }
      setInput("");
      return;
    }
  
    let output = "";
    if (connectedServer === 'RetroVault' && retroVaultModule.isActive) {
      const rvOutput = retroVaultModule.handleCommand(cmd, args.slice(1));
      simulateTyping(`> ${command}\n${rvOutput}`);
    } else if (connectedServer === 'ShadowMarket' && shadowMarketModule.isActive) {
      const smOutput = shadowMarketModule.handleCommand(cmd, args.slice(1));
      simulateTyping(`> ${command}\n${smOutput}`);
    } else if (connectedServer === 'HiddenNet' && hiddenNetModule.isActive) {
      const hnOutput = hiddenNetModule.handleCommand(cmd, args.slice(1));
      simulateTyping(`> ${command}\n${hnOutput}`);
    } else if (connectedServer === 'CyberNexus' && cyberNexusModule.isActive) {
      const cnOutput = cyberNexusModule.handleCommand(cmd, args.slice(1));
      simulateTyping(`> ${command}\n${cnOutput}`);
    } else if (connectedServer === 'VoidCache' && voidCacheModule.isActive) {
      const vcOutput = voidCacheModule.handleCommand(cmd, args.slice(1));
      simulateTyping(`> ${command}\n${vcOutput}`);
    } else if (connectedServer === 'DigitalDawn' && digitalDawnModule.isActive) {
      const ddOutput = digitalDawnModule.handleCommand(cmd, args.slice(1));
      simulateTyping(`> ${command}\n${ddOutput}`);
    } else {    
      // General command handling for non-connected state
      switch (cmd) {
        case 'help':
          output = handleHelpCommand();
          break;
        case 'scan':
          output = handleScanCommand();
          break;
        case 'list':
          output = handleListCommand();
          break;
        case 'matrix':
          output = handleMatrixCommand();
          break;
        case 'exit':
          if (challengeModule.isChallengeActive) {
            output = challengeModule.concludeChallenge();
          } else {
            output = "Not in a challenge to exit.";
          }
          break;
        case "startchallenge":
          output = challengeModule.startChallenge();
          break;
        case "decrypt":
          if (args.length > 1) {
            output = handleDecryptCommand(args);
          } else {
            output = "Usage: decrypt [file]";
          }
          break;
        // Add more cases as needed
        default:
          output = "Unknown command. Type 'help' for a list of commands.";
      }
    }
    simulateTyping(`> ${command}\n${output}`);
    setInput("");
  }, [challengeModule, connectedServer, simulateTyping, handleDecryptCommand, files, handleConnectCommand, retroVaultModule, shadowMarketModule, handleHelpCommand, handleScanCommand, handleListCommand, handleMatrixCommand, hiddenNetModule, cyberNexusModule, setFiles, voidCacheModule, digitalDawnModule]);
  
  return (
    <div className="terminal-container" ref={terminalRef}>
      {messages.map((msg, index) => <div key={index} className="terminal-line">{msg}</div>)}
      <input
        type="text"
        className="terminal-input"
        placeholder="Enter command..."
        value={input}
        onChange={e => setInput(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            processCommand(input);
            e.preventDefault();
          }
        }}
      />
      <MatrixRain isActive={matrixActive} onClose={() => setMatrixActive(false)} />
    </div>
  );
};

export default Terminal;